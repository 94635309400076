<template>
    <div class="e-manager">
        <b-card v-if="manager && manager.id==='staninvest'" class="rounded-corners-bcard mb-2 px-1">
            <b-row class="pb-1" align-v="start">
                <b-col cols="12" sm="8">
                    <h1 class="font-title" style="font-weight: bold;">Vaš upravnik</h1>
                    <div class="mt-2">
                        <h3 style="font-weight: bold;" class="mb-0">{{manager.name}}</h3>
                        <p class="address">
                            <span v-if="manager.title !== ''">{{manager.title}}<br></span>
                            {{manager.address}}<br>
                            {{manager.post}}
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" sm="4" class="d-flex justify-content-center justify-content-md-end mt-2 mt-sm-0">
                    <b-img v-if="manager.id === 'staninvest'" fluid width="180" :src="require('@/assets/images/esoseska/stan_invest_logo.svg')"/>
                    <b-img v-else fluid :src="require('@/assets/images/esoseska/habit_logo.png')"/>
                </b-col>
            </b-row>
            <div class="mx-0 pb-1 top-border d-flex align-items-sm-end justify-content-sm-between flex-sm-row flex-column flex-sm-wrap" style="gap: 1rem;">
                <div class="contact-info mt-1">
                    <div class="d-flex align-items-center">
                        <span class="mr-1"><fa class="text-primary address-icon" icon="phone-alt"/></span>
                        <a class="contact-text" style="color: black;" :href="'tel:' + manager.phone">{{manager.phone}}</a>
                    </div>
                    <div class="d-flex align-items-center">
                        <span class="mr-1"><fa class="text-primary address-icon mt-1" icon="envelope"/></span>
                        <a class="contact-text mt-1" style="color: black; word-break: break-all;" :href="'mailto:' + manager.email">{{manager.email}}</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a :href="manager.website" target="_blank">
                        <rounded-button><span class="website-button">{{manager.website.replace('https://', '')}}</span></rounded-button>
                    </a>
                </div>
            </div>
        </b-card>

        <b-card v-if="manager && manager.id==='habit'" class="rounded-corners-bcard mb-2 px-1">
            <b-row align-v="center"  class="pb-1">
                <b-col  cols="12" sm="5" class="mt-2 mt-sm-0" >
                    <h1 class="font-title" style="font-weight: bold;">Vaš upravnik</h1>
                    <h3 style="font-weight: bold;" class="mb-0">{{manager.title}}</h3>
                </b-col>
                <b-col cols="12" sm="5" class="d-flex justify-content-center justify-center mt-2 mt-sm-0">
                    <b-img class="border-2" fluid :src="require('@/assets/images/esoseska/habit_logo.png')"/>
                </b-col>
            </b-row>


            <b-row class="pb-1" align-v="end">
                <b-col cols="12" sm="6" class="bottom-border h-100" v-for="(item,index) in manager.array_pe" :key="index" >
                    <div class="mt-2">
                        <h3 style="font-weight: bold;" class="mb-0">{{item.name}}</h3>
                        <div class="d-flex align-items-center justify-start">
                            <span class="mr-1"><fa class="text-primary address-icon mt-1" icon="envelope"/></span>
                            <a class="contact-text mt-1 mail" style="color: black; word-break: break-all;" :href="'mailto:' + item.email">{{item.email}}</a>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-end justify-end">
                <div>
                    <a :href="manager.website" target="_blank">
                        <rounded-button><span class="website-button">{{manager.website.replace('https://', '')}}</span></rounded-button>
                    </a>
                </div>
            </div>
        </b-card>
        
        <b-row>
            <b-col cols="12" lg="12">
                <b-card class="p-0 h-100 rounded-corners-bcard px-1 pb-1">
                    <b-row class="h-100">
                        <b-col cols="12">
                            <h1 class="font-title" style="font-weight: bold;">Oglasna deska</h1>
                            <h3 class="mb-2" style="font-weight: bold;">Zadnja obvestila</h3>
                            <div class="flex-column bulletin" v-for="(item, index) of bulletins" :key="'bulletin'+index">
                                <h3 class="cursor-pointer bulletin-title" @click="$router.push('/e-upravnik/oglasna-deska/obvestilo/'+item.id); $scrollToTop()">{{item.title}}</h3>
                                <!-- <hr style="border-top: 1px solid #72a5d8;"> -->
                            </div>
                        </b-col>
                        <b-col class="d-flex justify-content-end align-items-end" cols="12">
                            <rounded-button class="text-right" @click="$router.push('/e-upravnik/oglasna-deska')">PRIKAŽI VEČ</rounded-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <news-carousel custom-class="mt-2" />
    </div>
</template>

<script>
    import {BRow, BCol, BImg, BCard} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import NewsCarousel from '@/views/Components/NewsCarousel'

    export default {
        components: {
            BRow,
            BCol,
            BImg,
            BCard,
            NewsCarousel,
            RoundedButton
        },
        data() {
            return {
                errorImage: true,
                chartDate: this.dayjs(new Date()).format('MMMM YYYY'),
                bulletins: [],
                units: [],
                series: [],
                colors: ['#72a5d8', '#cb64a6', '#bed330', '#7a287d', '#b5d7f4', '#e61e25', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7'],
                chartOptions: {
                    labels: [],
                    colors: ['#72a5d8', '#cb64a6', '#bed330', '#7a287d', '#b5d7f4', '#e61e25', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7']
                },
                voting: [
                    {id: 0, name: 'Podpisovanje listine za spremembo cene vzdrževanja'}, {
                        id: 1,
                        name: 'Podpisovanje listine za menjavo delilnikov'
                    }
                ],
                manager: null
            }
        },
        methods: {
            onError() {
                this.errorImage = false
            },
            async loadRazdelilnik() {

                if ((this.units && this.units.length === 0) || !this.units) {
                    return
                }

                try {
                    const date = this.dayjs(new Date()).format('YYYY-MM')

                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/razdelilnik/redni_obracun/${date}/${this.units[0].sifra}`)
                    this.data = res.data
                    this.setCostPieChartData(this.data.list_stroskov_razdelilnika_za_graf)
     
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
    
                } finally {
                    this.showLoader = false
                }
            },
            capitalizeFirstLetter(string) {
                if (!string) return ''
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            },
            setCostPieChartData(listOfCosts) {
                this.series = []
                this.chartOptions.labels = []
                if (!listOfCosts || !listOfCosts.length) return

                for (const cost of listOfCosts) {
                    this.series.push(cost.skupajzddv)
                    this.chartOptions.labels.push(this.capitalizeFirstLetter(cost.skupinastroskanaziv))
                }
            },
            cssVars(x) {
                return {
                    'background-color':  this.colors[x],
                    'opacity': 0.1
                }
            },
            getColor(item) {
                if (item > 50) {
                    return 'green'
                } else if (item > 30) {
                    return 'yellow'
                } else if (item > 10) {
                    return 'red'
                }
                return ''
            },
            /*carouselReroute(index) {
                window.location.href = this.bannerArray[index].bannerLink
            },*/
            async loadData() {
                this.showLoader = true

                try {
                    const loadUnits = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                    this.units = loadUnits.data

                    /* const loadBanners = await this.$http.get('/api/user/v1/banner/')
                    this.bannerArray = loadBanners.data.banners*/

                    await this.loadBulletins()
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadBulletins() {
                let buildings = []
                if (this.units && this.units.length > 0) {
                    for (const item of this.units) {
                        buildings.push(`&buildings.[]=${item.objekt_sifra}&`)
                    }
                    const buildings1 = [...new Set(buildings)]
                    buildings = buildings1
                }
                buildings.join('')

                const response = await this.$http.get(`/api/user/v1/e_manager/bulletin/?perPage=6&&${buildings}`)
                this.bulletins = response.data.items
            }
        },
        async mounted() {
            if (this.$router.currentRoute !== '/e-upravnik') {
                this.$router.push({path:'/e-upravnik'})
            }
            await this.loadData()
            await this.loadRazdelilnik()

            if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                if (this.$store.state.user.userData.manager === this.$store.state.app.managers[0].id) this.manager = this.$store.state.app.managers[0]
                else this.manager = this.$store.state.app.managers[1]
            }
        }
    }
</script>

<style scoped>
.e-manager .top-border{
    border-top: 1px solid #72a5d8 !important;
}
.bottom-border{
    border-bottom: 1px solid #72a5d8 !important;
}
.e-manager .font-title{
    color: #72a5d8;
    font-size: 2.3rem;
}
.rounded-corners-bcard{
    border-radius: 10px !important;
}
.second-div .font-title-white{
    font-weight: bold;
    color: white;
    font-size: 2.3rem;
}
.second-div .font{
    font-weight: bold;
    color: white;
    font-size: 1.4em;
    margin-bottom: 0.25em !important;
}
.e-manager .card .card-footer {
    border: none;
    padding: 0;
}
.e-manager .progress {
    border-radius: 0;
    background-color: white;
}
.e-manager .progress-bar {
    border-radius: 0 !important;
}
.address {
    font-size: 1.3em;
    line-height: 22px;
}
.address-icon {
    font-size: 2.25em;
}
.contact-info .contact-text {
    font-size: 1.6em;
    vertical-align: middle;
}
.website-button{
    font-size: 20px;
}
.bulletin-title{
    font-size: 1.3em;
    border-bottom: 1px solid #72a5d8;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin: 0 !important;
}
.bulletin:last-child .bulletin-title {
    border: none !important;
}
.white-button-home{
    background-color: white !important;
    color: #72a5d8 !important;
    border-radius: 42px !important;
}

.white-button-home:hover{
    box-shadow: none !important;
    background: #cbe2f8 !important;
    border-color: #cbe2f8 !important;
}
.unit-image {
    height: 100% !important; 
    width: 100% !important; 
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 350px;
}

@media only screen and (max-width: 576px) {
.unit-image {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px !important;
}
}
.sosed-blaz img {
    height: 100% !important; 
    width: 100% !important; 
    object-fit: cover;
    border-radius: 10px !important;
}
.mail{
    white-space: nowrap;
}
</style>
<style>
.e-manager .progress-bar-parent .progress-bar {
    height: 60% !important;
}
.e-manager .progress-bar-parent .col-form-label {
    padding-bottom: 0.3em !important;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
}
.e-manager .progress-bar-parent {
    padding-left: 1em !important;
}

.carousel-indicators li {
    background-color: black;
}

@media only screen and (max-width: 550px) {
    .info_placement {
        justify-content: center;
    }
}

@media only screen and (min-width: 551px) {
    .info_placement {
        justify-content: start;
    }
}
</style>